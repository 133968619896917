import { Link } from "react-router-dom";

export default function PublicationIndex() {
    
    return (
        <div className='container-fluid mb-2'>
            <div className="row">
                <div className="col">
                    <h2 className="mb-4">Publication véhicules (PRC)</h2>
                    <div className="card my-4">
                        <div className="card-header" style={{backgroundColor:"#ebedeb"}}>
                            Paramétrages
                        </div>
                        <ul className="list-group list-group-flush">
                            <Link to='/publication/compteClient' className="list-group-item list-group-item-action">Compte client</Link>
                            <Link to='/publication/abonnements' className="list-group-item list-group-item-action">Abonnements</Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
