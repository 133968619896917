import { SiteAbonnementDTO } from "./PublicationUtilsEnums";


interface Props {
    setDealershipToCreate: (dealershipToCreate: SiteAbonnementDTO) => void
    dealershipToCreate: SiteAbonnementDTO
    saveDealership: (e: any) => void
    resetDealershipToCreate: () => void
    dealerships: any[]
    selectedActivityId: number | undefined
    getExistingDealershipsId: (advertiserId: number) => number[]
}

export default function ModalCreationSiteAbonnement(props: Props) {

    return (
        <div data-backdrop="static" className="modal fade" id="addModal" role="dialog" aria-labelledby="addModalLabel" aria-hidden="true">
            <form onSubmit={props.saveDealership} id="addDealership">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addModalLabel">Ajouter un site à l'abonnement</h5>
                            <button onClick={props.resetDealershipToCreate} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <select
                                required
                                value={props.dealershipToCreate.IDSite}
                                onChange={(e) => props.setDealershipToCreate({ ...props.dealershipToCreate, IDSite: Number(e.currentTarget.value) })}
                                id='IDTFicheCli'
                                name="IDTFicheCli"
                                className="form-control form-control-sm mb-2"
                            >
                                <option value="">Choisir un site</option>
                                {props.dealerships.filter((d) => {
                                    return ((d.IDActivite ?? d.Plaque_IDActivite) === props.selectedActivityId) && !props.getExistingDealershipsId(props.dealershipToCreate.IDAnnonceur).includes(d.IDSite)
                                }).map((d, i) => {
                                    return <option key={i} value={d.IDSite}>{d.Descr}</option>
                                })}
                            </select>
                            <div className="d-flex px-4 py-2">
                                <span>Publication automatique VO</span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="PublicationAutoVO"
                                    checked={props.dealershipToCreate.PublicationAutoVO}
                                    onChange={(e) => props.setDealershipToCreate({ ...props.dealershipToCreate, PublicationAutoVO: (e.currentTarget.checked) })}
                                />
                            </div>
                            <div className="d-flex px-4 py-2">
                                <span>Publication automatique VN</span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="PublicationAutoVN"
                                    checked={props.dealershipToCreate.PublicationAutoVN}
                                    onChange={(e) => props.setDealershipToCreate({ ...props.dealershipToCreate, PublicationAutoVN: (e.currentTarget.checked) })}
                                />
                            </div>
                            <div className="d-flex px-4 py-2">
                                <span>Publication automatique VD</span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="PublicationAutoVD"
                                    checked={props.dealershipToCreate.PublicationAutoVD}
                                    onChange={(e) => props.setDealershipToCreate({ ...props.dealershipToCreate, PublicationAutoVD: (e.currentTarget.checked) })}
                                />
                            </div>
                            <div className="  py-2">
                                <span>Email traceur</span>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="EmailTraceur"
                                    id="EmailTraceur"
                                    value={props.dealershipToCreate.EmailTraceur}
                                    onChange={(e) => props.setDealershipToCreate({ ...props.dealershipToCreate, EmailTraceur: e.currentTarget.value })}
                                />
                            </div>
                            <div className="  py-2">
                                <span>Tel traceur</span>
                                <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    name="TelTraceur"
                                    id="TelTraceur"
                                    value={props.dealershipToCreate.TelTraceur}
                                    onChange={(e) => props.setDealershipToCreate({ ...props.dealershipToCreate, TelTraceur: e.currentTarget.value })}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button id="closeButtonAddModalDealership" onClick={props.resetDealershipToCreate} type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                            <input value="Sauvegarder" type="submit" className="btn btn-primary" />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}