import { useEffect, useMemo, useState } from "react"
import BackOfficeAPIClient from "../../clients/BackofficeAPIClient"
import _, { cloneDeep, find, findIndex, groupBy, orderBy, uniqBy } from "lodash"
import { ActivitePublicationConfig, Annonceur, PublicationSubscription, SiteAbonnement, SiteAbonnementDTO } from "./PublicationUtilsEnums"
import ModalCreationAbonnement from "./ModalCreationAbonnement"
import ModalCreationSiteAbonnement from "./ModalCreationSiteAbonnement"

export default function PublicationAbonnement() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const [activitiesConfig, setActivitiesConfig] = useState<ActivitePublicationConfig[]>([])
    const [advertisers, setAdvertisers] = useState<Annonceur[]>([])
    const [fetchingConfig, setFetchingConfig] = useState<boolean>(true)
    const [configLoaded, setConfigLoaded] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [updated, setUpdated] = useState<boolean | null>(null)
    const [selectedActivity, setSelectedActivity] = useState<string>("")
    const [subscriptionsGroupByAdvertiser, setSubscriptionsGroupByAdvertiser] = useState<Record<number, PublicationSubscription[]>>({})
    const [subscriptions, setSubscriptions] = useState<PublicationSubscription[]>([])
    const [dealershipsSubscriptions, setDealershipsSubscriptions] = useState<SiteAbonnement[]>([])
    const [dealerships, setDealerships] = useState<any[]>([])
    const [dealershipToDelete, setDealershipToDelete] = useState<SiteAbonnement | null>(null)
    const [submitting, setSubmitting] = useState<boolean>(false)

    const selectedActivityId = useMemo(() => {
        return activitiesConfig.find((a) => a.LibelleActivite === selectedActivity)?.IDActivite
    }, [selectedActivity])

    const [dealershipToCreate, setDealershipToCreate] = useState<SiteAbonnementDTO>(new SiteAbonnementDTO())
    const [subscription, setSubscription] = useState<PublicationSubscription>(new PublicationSubscription({ NbVehiculesMax: 0 }))


    useEffect(() => {
        fetchConfig(true)
    }, [])

    useEffect(() => {
        if (configLoaded) {
            setUpdated(false)
        }
    }, [configLoaded])

    const fetchConfig = async (withLoading: boolean) => {
        try {
            if (withLoading) {
                setFetchingConfig(true)
            }
            await fetchActivitiesConfig()
            const advertisers = await fetchAnnonceurs()
            await fetchSubscriptions(advertisers)
            await fetchDealershipsSubscriptions()
            await fetchSites()
            setConfigLoaded(true)
        }
        catch (err: any) {
            setError(err.message)
        }
        finally {
            setFetchingConfig(false)
        }
    }

    const fetchSites = async () => {
        try {
            setDealerships(orderBy(await backofficeApiClient.get<any[]>("/sites"), "Descr"))
        }
        catch {
            throw new Error("Erreur lors de la récupération de la configuration des sites")
        }
    }

    const fetchDealershipsSubscriptions = async () => {
        try {
            const dealershipsSubscriptions = await backofficeApiClient.get<any[]>("/siteAbonnement")
            setDealershipsSubscriptions(dealershipsSubscriptions)
        }
        catch {
            throw new Error("Erreur lors de la récupération de la configuration des sites")
        }
    }

    const fetchSubscriptions = async (advertisers: Annonceur[]) => {
        try {
            const subs = await backofficeApiClient.get<PublicationSubscription[]>("/abonnementsAnnonceur")
            setSubscriptions(subs)
            subs.forEach((s) => {
                const find = advertisers.find((a) => a.IDAnnonceur === s.IDAnnonceur)
                s.Annonceur = find
            })

            setSubscriptionsGroupByAdvertiser(groupBy(subs, 'IDAnnonceur'))
        }
        catch {
            throw new Error("Erreur lors de la récupération de la configuration du groupe")
        }
    }

    const fetchActivitiesConfig = async () => {
        try {
            const activities = _.orderBy(await backofficeApiClient.get<ActivitePublicationConfig[]>("/publication/activities"), "IDActivite")
            setSelectedActivity(activities.filter((a) => a.Actif === true)[0].LibelleActivite)
            setActivitiesConfig(activities)
        }
        catch {
            throw new Error("Erreur lors de la récupération de la configuration des activités")
        }
    }

    const fetchAnnonceurs = async () => {
        try {
            const values = await backofficeApiClient.get<Annonceur[]>("/annonceurs")
            setAdvertisers(values)
            return values
        }
        catch {
            throw new Error("Erreur lors de la récupération des annonceurs")
        }
    }

    const getDealershipsSubscriptions = (idSubscription: number) => {
        let dealerships = []

        dealerships = dealershipsSubscriptions.filter((d) => {
            const idActivity = d.SiteIDActivite ?? d.PlaqueIDActivite
            return d.IDAbonnement === idSubscription && idActivity === selectedActivityId
        })

        return orderBy(dealerships, "LibelleSite")
    }

    const deleteDealershipSubscription = async () => {
        try {
            if (dealershipToDelete) {
                await backofficeApiClient.delete(`/siteAbonnement`, dealershipToDelete)
                setDealershipToDelete(null)
                document.getElementById("closeButtonDeleteDealershipModal")?.click()
                await fetchDealershipsSubscriptions()
            }
        }
        catch {
            throw new Error("Erreur lors de la suppression du site de l'abonnement")
        }

    }

    const onChangeNbVehicleSubscription = (idSubscription: number, value: number) => {
        setUpdated(true)
        const editSubscriptions = cloneDeep(subscriptions)
        editSubscriptions.forEach((s) => {
            if (s.IDAbonnement === idSubscription) {
                s.NbVehiculesMax = value
            }
        })

        setSubscriptionsGroupByAdvertiser(groupBy(editSubscriptions, 'IDAnnonceur'))
        setSubscriptions(editSubscriptions)
    }

    const saveSubscriptions = async () => {
        try {
            setSubmitting(true)
            await backofficeApiClient.put("/abonnementsAnnonceur", subscriptions)
            await updateDealerships()
            setUpdated(false)
        }
        catch (erreur) {
            throw new Error("Erreur lors de l'enregistrement'")
        }
        finally {
            setSubmitting(false)
        }
    }

    const saveDealership = (e: any) => {
        e.preventDefault()

        fetch(localStorage.backoffice_api + '/api/siteAbonnement', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.JWT,
            },
            body: JSON.stringify(dealershipToCreate)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        alert(value.message)
                    });
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(async () => {
                document.getElementById("closeButtonAddModalDealership")?.click()
                setFetchingConfig(true)
                await fetchDealershipsSubscriptions()
                setFetchingConfig(false)
            })
            .catch((error) => {
                setSubmitting(false)
            })
    }

    const resetDealershipToCreate = () => {
        setDealershipToCreate({
            ...dealershipToCreate,
            EmailTraceur: "",
            TelTraceur: "",
            IDSite: 0,
            IDAbonnement: undefined,
            PublicationAutoVD: false,
            PublicationAutoVN: false,
            PublicationAutoVO: false,
        })
    }

    const getExistingDealershipsId = (advertiserId: number): number[] => {
        const existingDealershipIds: number[] = []
        const advertiser = subscriptionsGroupByAdvertiser[advertiserId]

        advertiser?.forEach((a) => {
            const list = dealershipsSubscriptions.filter((d) => {
                return d.IDAbonnement === a.IDAbonnement
            })
            list.forEach((l) => {
                existingDealershipIds.push(l.IDSite)
            })
        })

        return existingDealershipIds
    }

    const checkAdvertiserConfiguration = (advertiserId: number) => {
        const dealershipsId = getExistingDealershipsId(advertiserId)

        let uniqDealershipsId: number[] = []
        uniqDealershipsId = uniqBy(dealershipsId, (d) => d)

        return dealershipsId.length !== uniqDealershipsId.length
    }

    const isDealershipDuplicated = (arr: number[], num: number) => {
        let count = 0;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === num) {
                count++;
            }
            if (count > 1) {
                return true;
            }
        }

        return false;
    }

    const createSubscription = (e: any) => {
        e.preventDefault()
        setSubmitting(true)
        fetch(localStorage.backoffice_api + '/api/abonnementsAnnonceur', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.JWT,
            },
            body: JSON.stringify({ ...subscription, IDActivite: selectedActivityId })
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        alert(value.message)
                    });
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(async () => {
                document.getElementById("closeButtonCreateSubscriptionModal")?.click()
                fetchConfig(true)
            })
            .catch((error) => {
                console.log(error)
            }).finally(() => {
                setSubmitting(false)
            })
    }

    const onChangeDealershipSubscription = (dealershipId: number, subscriptionId: number, dealership: SiteAbonnement) => {
        setUpdated(true)
        const tabDealerships = cloneDeep(dealershipsSubscriptions)
        const index = findIndex(tabDealerships, (d) => {
            return d.IDSite === dealershipId && d.IDAbonnement === subscriptionId
        })

        tabDealerships[index] = dealership

        setDealershipsSubscriptions(tabDealerships)
    }

    const updateDealerships = async () => {
        fetch(localStorage.backoffice_api + '/api/siteAbonnement', {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                authorization: localStorage.JWT,
            },
            body: JSON.stringify(dealershipsSubscriptions)
        })
            .then((response) => {
                if (!response.ok) {
                    response.json().then((value) => {
                        alert(value.message)
                    });
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(async () => {
                fetchConfig(false)
            })
            .catch((error) => {
                console.log(error)
            }).finally(() => {
                setSubmitting(false)
            })
    }


    const checkBeforeAction = async (callback: () => void) => {
        if (updated && confirm("Des modifications n'ont pas été sauvegardés. Voulez-vous les sauvegardées avant de continuer ?")) {
            await saveSubscriptions()
        }

        callback()
    }

    return (
        <div className='container-fluid mb-2'>
            <div className="row">
                <div className="col">
                    <h2 className="mb-4">Paramétrage Abonnements</h2>
                    {fetchingConfig ?
                        <div className="d-flex flex-row align-items-center justify-content-center">
                            <i className='fa fa-spinner fa-spin fa-fw'></i>
                        </div>
                        : error ?
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <i className='fa fa-warning'></i>
                                {error}
                            </div>
                            :
                            <>
                                <div className="my-4 d-flex justify-content-between">
                                    <button type="button" className="btn btn-light" data-toggle="modal" data-target="#createModal">
                                        <i className="fa fa-plus"></i> Ajouter un abonnement
                                    </button>
                                    {updated &&
                                        <div className="d-flex flex-row  align-items-center mr-3">
                                            <div className="alert alert-warning m-0 p-1 mr-1">
                                                <i className="fa fa-warning mr-1"></i>
                                                Modifications non sauvegardées
                                            </div>
                                            <button onClick={saveSubscriptions} type="submit" className="btn btn-primary m-0 p-1 px-2" >
                                                {submitting && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                                                Sauvegarder
                                            </button>
                                        </div>
                                    }
                                </div>
                                <div className="d-flex flex-row justify-content-between mb-4">
                                    <ul className="nav nav-tabs d-flex flex-grow-1" id="myTab">
                                        {activitiesConfig.filter((a) => a.Actif).map((a) => {
                                            return (
                                                <li className="nav-item">
                                                    <a className={`nav-link ${selectedActivity === a.LibelleActivite ? "active" : ""}`} href="#" id="groupe-tab" role="tab" onClick={() => setSelectedActivity(a.LibelleActivite)}>{a.LibelleActivite}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                {Object.values(subscriptionsGroupByAdvertiser).map((sub) => {
                                    const filtersSubscription = sub.filter((s)=>s.IDActivite === selectedActivityId)
                                    if (filtersSubscription.length === 0) {
                                        return;
                                    }
                                    return (
                                        <div className="card my-4">
                                            <div className="card-header">
                                                <span style={{ fontSize: '1.2em' }} className="font-weight-bold"> {sub[0].Annonceur?.Nom}</span>
                                                {checkAdvertiserConfiguration(sub[0].IDAnnonceur) &&
                                                    <span className="ml-4 text-danger">
                                                        <i className="fa fa-exclamation-triangle text-danger mr-2" />
                                                        <span>Problème de configuration : chaque site ne peut avoir qu’un abonnement par annonceur. Merci de corriger votre configuration ci-dessous</span>
                                                    </span>
                                                }
                                            </div>
                                            <div className="card-body">
                                                {filtersSubscription.map((s) => {
                                                    return (
                                                        <div key={s.IDAbonnement} className="bg-light p-2 rounded my-3 border">
                                                            <div className="d-flex align-items-center mb-2">
                                                                <span style={{ fontSize: '1.2em' }} className="font-weight-bold">{s.Identifiant}</span>
                                                                <span className="pl-4">Nombre maximum de véhicules à publier automatiquement :</span>
                                                                <input onChange={(e) => onChangeNbVehicleSubscription(s.IDAbonnement, Number(e.currentTarget.value))} className="ml-2" type="number" value={s.NbVehiculesMax} />
                                                            </div>
                                                            <table className="table table-bordered bg-white">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Site</th>
                                                                        <th scope="col">Publication automatique VO</th>
                                                                        <th scope="col">Publication automatique VN</th>
                                                                        <th scope="col">Publication automatique VD</th>
                                                                        <th scope="col">Email traceur</th>
                                                                        <th scope="col">Tel traceur</th>
                                                                        <th scope="col">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {getDealershipsSubscriptions(s.IDAbonnement).map((d: SiteAbonnement) => {
                                                                        const isDisabled = isDealershipDuplicated(getExistingDealershipsId(s.IDAnnonceur), d.IDSite)
                                                                        return (
                                                                            <tr key={d.IDAbonnement + "_" + d.IDSite} className={isDisabled ? "bg-light" : ""}>
                                                                                <td className={isDisabled ? "text-secondary" : ""}>{d.LibelleSite}</td>
                                                                                <td>
                                                                                    <div className="d-flex justify-content-center">
                                                                                        <input
                                                                                            disabled={isDisabled}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="PublicationAutoVO"
                                                                                            checked={d.PublicationAutoVO}
                                                                                            onChange={(e) => onChangeDealershipSubscription(d.IDSite, d.IDAbonnement, { ...d, PublicationAutoVO: e.currentTarget.checked })}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="d-flex justify-content-center">
                                                                                        <input
                                                                                            disabled={isDisabled}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="PublicationAutoVN"
                                                                                            checked={d.PublicationAutoVN}
                                                                                            onChange={(e) => onChangeDealershipSubscription(d.IDSite, d.IDAbonnement, { ...d, PublicationAutoVN: e.currentTarget.checked })}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="d-flex justify-content-center">
                                                                                        <input
                                                                                            disabled={isDisabled}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            name="PublicationAutoVD"
                                                                                            checked={d.PublicationAutoVD}
                                                                                            onChange={(e) => onChangeDealershipSubscription(d.IDSite, d.IDAbonnement, { ...d, PublicationAutoVD: e.currentTarget.checked })}
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        disabled={isDisabled}
                                                                                        className="form-control form-control-sm"
                                                                                        type="text"
                                                                                        name="EmailTraceur"
                                                                                        id="EmailTraceur"
                                                                                        value={d.EmailTraceur}
                                                                                        onChange={(e) => onChangeDealershipSubscription(d.IDSite, d.IDAbonnement, { ...d, EmailTraceur: e.currentTarget.value })}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        disabled={isDisabled}
                                                                                        className="form-control form-control-sm"
                                                                                        type="text"
                                                                                        name="TelTraceur"
                                                                                        id="TelTraceur"
                                                                                        value={d.TelTraceur}
                                                                                        onChange={(e) => onChangeDealershipSubscription(d.IDSite, d.IDAbonnement, { ...d, TelTraceur: e.currentTarget.value })}
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <div className="d-flex justify-content-center">
                                                                                        <button onClick={() => checkBeforeAction(() => setDealershipToDelete(d))} type="button" className="btn btn-danger btn-sm" data-toggle="modal" data-target="#deleteModal">
                                                                                            <i className="fa fa-trash"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    <tr>
                                                                        <td colSpan={6}></td>
                                                                        <td>
                                                                            <div className="d-flex justify-content-center">
                                                                                <button onClick={() => checkBeforeAction(() => setDealershipToCreate({ ...dealershipToCreate, IDAbonnement: s.IDAbonnement, IDAnnonceur: sub[0].IDAnnonceur }))} type="button" className="btn btn-primary btn-sm" data-toggle="modal" data-target="#addModal">
                                                                                    <i className='fa fa-plus'></i>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div data-backdrop="static" className="modal fade" id="deleteModal" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Confirmation</h5>
                                                <button onClick={() => setDealershipToDelete(null)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                Êtes-vous sûr de vouloir supprimer ce site de cet abonnement ?
                                            </div>
                                            <div className="modal-footer">
                                                <button id="closeButtonDeleteDealershipModal" onClick={() => setDealershipToDelete(null)} type="button" className="btn btn-secondary" data-dismiss="modal">Annuler</button>
                                                <button onClick={deleteDealershipSubscription} type="button" className="btn btn-danger">Supprimer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ModalCreationSiteAbonnement
                                    dealershipToCreate={dealershipToCreate}
                                    dealerships={dealerships}
                                    resetDealershipToCreate={resetDealershipToCreate}
                                    getExistingDealershipsId={getExistingDealershipsId}
                                    saveDealership={saveDealership}
                                    selectedActivityId={selectedActivityId}
                                    setDealershipToCreate={setDealershipToCreate}
                                />
                                <ModalCreationAbonnement
                                    setSubscription={setSubscription}
                                    subscription={subscription}
                                    createSubscription={createSubscription}
                                    advertisers={advertisers}
                                    selectedActivityId={selectedActivityId}
                                />
                            </>
                    }
                    {updated &&
                        <div className="d-flex flex-row  align-items-center justify-content-center mr-3 mb-4">
                            <div className="alert alert-warning m-0 p-1 mr-1">
                                <i className="fa fa-warning mr-1"></i>
                                Modifications non sauvegardées
                            </div>
                            <button type="submit" className="btn btn-primary m-0 p-1 px-2" onClick={saveSubscriptions}>
                                {submitting && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                                Sauvegarder
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
