import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import EsellerLeadUtilisateurs from './Utilisateurs';
import EsellerLeadSites from './Sites';
import EsellerLeadSynthese from './Synthese';
import Utils from '../../../Utils';
import { workflowsBDC } from '../../../enums/WorkflowsBDC';

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      config: null,
      isNewConfig: true,
      updatedConfig: false,
      fetchInProgressConfig: false,
      fetchInProgressReporting: false,

      linkEsellerLead: '',
    }
  }

  componentWillMount() {
    this.fetchConfig()
    this.fetchLinks()
  }

  fetchConfig() {
    fetch(localStorage.backoffice_api + '/api/interfaces/esellerLead/config', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      console.log(json)
      this.setState({
        config: json,
        isNewConfig: (json.IDClient.length > 0) ? false : true,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchLinks() {
    fetch(localStorage.backoffice_api + '/api/bdref3/urls', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var indexLinks = lodash.findIndex(json, (l) => {
        return l.URL.toLowerCase().search('e-seller.selectup.com') !== -1 || l.URL.toLowerCase().search('smit.selectup.com') !== -1;
      })

      if (indexLinks < 0) {
        return;
      }

      var linkEsellerLead = '';
      if (Utils.fromJWT('serverName') === 'PROD-CRM-SQL1' || Utils.fromJWT('serverName') === 'PROD-CRM-SQL2' || Utils.fromJWT('serverName') === 'PROD-CRM-SQL3') {
        linkEsellerLead = 'http://'+json[indexLinks].URL.replace('e-seller.selectup.com', 'lead.e-seller.selectup.com').replace('smit.selectup.com', 'lead.e-seller.selectup.com');
      }
      else {
        linkEsellerLead = 'http://'+json[indexLinks].URL.replace('e-seller.selectup.com', 'dev.lead.e-seller.selectup.com').replace('smit.selectup.com', 'dev.lead.e-seller.selectup.com');
      }

      this.setState({
        linkEsellerLead: linkEsellerLead,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  handleSubmitConfig(event) {
    event.preventDefault();

    this.setState({
      fetchInProgressConfig: true,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/esellerLead/config', {
      method: 'PUT',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.config)
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        config: null,
        updatedConfig: false,
        fetchInProgressConfig: false,
      }, () => {
        this.fetchConfig();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeConfig(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    if (name === 'Actif' && !this.state.config.IDClient) {
      alert('Vous devez renseigner un IDClient pour pouvoir activer l\'interface');
      return;
    }

    var config = this.state.config

    if (type === 'checkbox') {
      config[name] = event.target.checked
    }
    else {
      config[name] = value
    }

    this.setState({
      updatedConfig: true,
      config: config,
    })
  }

  reportingCreateLeadMenu() {

    if (this.state.fetchInProgressReporting) {
      return;
    }

    this.setState({
      fetchInProgressReporting: true,
    });

    fetch(localStorage.backoffice_api + '/api/interfaces/esellerLead/reportingMenu', {
      method: 'POST',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        confif: null,
        fetchInProgressReporting: false,
      }, () => {
        this.fetchConfig();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {

    if (!this.state.config)
      return null;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>eSeller Lead</h2>

            <br />

            <ul className="nav nav-tabs" id="myTab">
              <li className="nav-item">
                <a className="nav-link active" id="configuration-tab" data-toggle="tab" href="#configuration" role="tab" aria-controls="configuration" aria-selected="true">Configuration</a>
              </li>
              {this.state.config.Actif && this.state.linkEsellerLead ?
                [<li className="nav-item" key='sites-tab-li'>
                  <a className="nav-link" id="sites-tab" data-toggle="tab" href="#sites" role="tab" aria-controls="sites" aria-selected="true">Sites</a>
                </li>,
                <li className="nav-item" key='users-tab-li'>
                  <a className="nav-link" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="users" aria-selected="true">Utilisateurs</a>
                </li>,
                <li className="nav-item" key='synthese-tab-li'>
                  <a className="nav-link" id="synthese-tab" data-toggle="tab" href="#synthese" role="tab" aria-controls="synthese" aria-selected="true">Synthèse</a>
                </li>]
              : null}
            </ul>

            <div className="tab-content" id="myTabContent" style={{ paddingTop: '15px' }}>
              <div className="tab-pane fade show active" id="configuration" role="tabpanel" aria-labelledby="configuration-tab">
                <div>
                  <br />

                  {this.state.config.Actif && this.state.linkEsellerLead ?
                    <div className="row" style={{ marginBottom: '10px' }}>
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col">
                            <a href={this.state.linkEsellerLead} target='_blank' className="btn btn-outline-primary btn-sm"><i className="fa fa-external-link" aria-hidden="true"></i> Accéder à eSeller Lead</a>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        {!this.state.config.reportingLeadMenuExist ?
                          <div className="row">
                            <div className="col-3"></div>
                            <div className="col">
                              <a className="btn btn-primary btn-sm" tabindex='9' onClick={this.reportingCreateLeadMenu.bind(this)}>
                                {this.state.fetchInProgressReporting ?
                                  <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                                : null}
                                Ajouter l'onglet LEADS au reporting
                              </a>
                            </div>
                          </div>
                        : null}
                      </div>
                    </div>
                  : null}

                  <form onSubmit={this.handleSubmitConfig.bind(this)}>

                    <div className="row">
                      <div className="col">
                        <div className="form-group row">
                          <label htmlFor="IDClient" className="col-sm-3 col-form-label">IDClient</label>
                          <div className="col-sm-6">
                            <input type="text" className="form-control form-control-sm" id="IDClient" name="IDClient" value={this.state.config.IDClient} onChange={this.onChangeConfig.bind(this)} maxLength="255" required='required' />
                            <small className="form-text text-muted">ID SalesForce siège du groupe (= identifiant pour l'application mobile)</small>

                          </div>
                        </div>
                      </div>

                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" name="Actif" checked={this.state.config.Actif} onChange={this.onChangeConfig.bind(this)} />
                                &nbsp; Actif
                              </label>
                              <small className="form-text text-muted">Si coché, eSeller Lead est actif sur les sites spécifiés en dessous</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.config.Actif ?
                      <div>
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_eSellerLeadGereLeadVO" checked={this.state.config.GRP_eSellerLeadGereLeadVO} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; GRP_eSellerLeadGereLeadVO
                                  </label>
                                  <small className="form-text text-muted">Si coché : eSeller Lead gère les leads à redistribuer de type VO</small>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_eSellerLeadGereLeadVN" checked={this.state.config.GRP_eSellerLeadGereLeadVN} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; GRP_eSellerLeadGereLeadVN
                                  </label>
                                  <small className="form-text text-muted">Si coché : eSeller Lead gère les leads à redistribuer de type VN</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_eSellerTeamGereLeadVO" checked={this.state.config.GRP_eSellerTeamGereLeadVO} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; GRP_eSellerTeamGereLeadVO
                                  </label>
                                  <small className="form-text text-muted">Si coché : eSeller Team gère les leads à redistribuer de type VO</small>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_eSellerTeamGereLeadVN" checked={this.state.config.GRP_eSellerTeamGereLeadVN} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; GRP_eSellerTeamGereLeadVN
                                  </label>
                                  <small className="form-text text-muted">Si coché : eSeller Team gère les leads à redistribuer de type VN</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_eSellerLeadAtelierMailRapport" checked={this.state.config.GRP_eSellerLeadAtelierMailRapport} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; GRP_eSellerLeadAtelierMailRapport
                                    <small className="form-text text-muted">Si coché : eSeller Lead Atelier : géstion simplifié avec email et rapport</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="form-group row">
                              <label htmlFor="drprofile_id" className="col-sm-3 col-form-label">GRP_eSellerLeadProcessBDC</label>
                              <div className="col-sm-6">
                                <select value={this.state.config.GRP_eSellerLeadProcessBDC ? this.state.config.GRP_eSellerLeadProcessBDC : ''} onChange={this.onChangeConfig.bind(this)} id='GRP_eSellerLeadProcessBDC' name="GRP_eSellerLeadProcessBDC" className="form-control form-control-sm">
                                  {!this.state.config.GRP_eSellerLeadProcessBDC ? 
                                    <option value='0'></option>
                                  :null}
                                  {workflowsBDC.map((workflow, i) => {
                                    return <option key={i} value={workflow.code}>BDC -&gt; {workflow.libelle}</option>
                                  })}
                                </select>
                                <small className="form-text text-muted ml-3">Correspond au workflow par défaut. Cependant, des workflows peuvent être définis par origine dans "Configuration =&gt; Origines", bouton "Workflows"</small>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="form-group row">
                              <label htmlFor="drprofile_id" className="col-sm-3 col-form-label">CallCenter Type création</label>
                              <div className="col-sm-6">
                                <select value={this.state.config.GRP_esellerLeadCallcenterTypeCreateLead ? this.state.config.GRP_esellerLeadCallcenterTypeCreateLead : ''} onChange={this.onChangeConfig.bind(this)} id='GRP_esellerLeadCallcenterTypeCreateLead' name="GRP_esellerLeadCallcenterTypeCreateLead" className="form-control form-control-sm">
                                  <option value=''>Classique (sans priorisation)</option>
                                  <option value='PriorisationKiamo'>Priorisation Kiamo</option>
                                  <option value='PriorisationAppelManuel'>Priorisation et appel manuel</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_esellerLeadEditRapportCloture" checked={this.state.config.GRP_esellerLeadEditRapportCloture} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; BDC peut traiter rapport clôt
                                    <small className="form-text text-muted">Si coché : eSeller Lead : les personnes du BDC peuvent éditer les rapports clôturés</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_esellerLeadCampagne" checked={this.state.config.GRP_esellerLeadCampagne} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; Module Campagne
                                    <small className="form-text text-muted">Si coché : eSeller Lead : activation de l'onglet campagne</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_BDCTraitementLeadAmontChefVente" checked={this.state.config.GRP_BDCTraitementLeadAmontChefVente} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; GRP_BDCTraitementLeadAmontChefVente
                                    <small className="form-text text-muted">Si coché : eSeller Lead : Traitement des leads par le BDC avant de transférer aux chefs des ventes</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_reportingTransformationCyclevente" checked={this.state.config.GRP_reportingTransformationCyclevente} onChange={this.onChangeConfig.bind(this)}/>
                                    &nbsp; GRP_reportingTransformationCyclevente
                                    <small className="form-text text-muted">Si coché : Le reporting taux de transofrmation se base sur le cycle de vente, sinon sur les offres</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label" style={{color: !this.state.config.GRP_BDCTraitementLeadAmontChefVente ? "#888" : null}}>
                                    <input disabled={!this.state.config.GRP_BDCTraitementLeadAmontChefVente} className={"form-check-input"} type="checkbox" name="GRP_BDCTraitementLeadAmontChefVente_NonTransmisCDV" checked={this.state.config.GRP_BDCTraitementLeadAmontChefVente && this.state.config.GRP_BDCTraitementLeadAmontChefVente_NonTransmisCDV} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; GRP_BDCTraitementLeadAmontChefVente_NonTransmisCDV
                                    <small className="form-text text-muted">Si coché : eSeller Lead : Lors de la création d'un lead depuis l'iframe avec origine CallWin, si le lead n'est pas transmis à un vendeur alors il tombe dans le pot commun des Chef des Ventes (nécessite GRP_BDCTraitementLeadAmontChefVente activé)</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_GestionVIP" checked={this.state.config.GRP_GestionVIP} onChange={this.onChangeConfig.bind(this)}/>
                                    &nbsp; GRP_GestionVIP
                                    <small className="form-text text-muted">Si coché : Activation de la gestion des clients VIP</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_BDCTraitementLeadsAPV" checked={this.state.config.GRP_BDCTraitementLeadsAPV} onChange={this.onChangeConfig.bind(this)} />
                                    &nbsp; APV : Traitement leads business par BDC
                                    <small className="form-text text-muted">Si coché : eSeller Lead APV : Les leads business (Prise de RDV/Demande de devis) doivent préalablement être traités par un BDC avant d'être transférés aux conseillers service</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input className="form-check-input" type="checkbox" name="GRP_PotCommunVendeursEmailCDV" checked={this.state.config.GRP_PotCommunVendeursEmailCDV} onChange={this.onChangeConfig.bind(this)}/>
                                    &nbsp; GRP_PotCommunVendeursEmailCDV
                                    <small className="form-text text-muted">Si coché : lorsqu'un lead est transféré du BDC vers le pot commun vendeurs, ou lorsqu'un nouveau lead arrive dans un workflow Vendeur, les Chefs des ventes du site reçoivent un email</small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="row">
                              <div className="col-3"></div>
                              <div className="col">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    : null}

                    <hr />

                    <h3 className='text-center' style={{ marginTop: '15px' }}>Configuration version mobile</h3>

                    <div className="row" style={{ marginTop: '25px' }}>
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <h4 style={{ marginLeft: '13px' }}>Chef des ventes</h4>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <h4 style={{ marginLeft: '13px' }}>Vendeur</h4>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" name="GRP_esellerLeadMobileChefVentesCloturer" checked={this.state.config.GRP_esellerLeadMobileChefVentesCloturer} onChange={this.onChangeConfig.bind(this)} />
                                &nbsp; Pouvoir clôturer une affaire
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" name="GRP_esellerLeadMobileVendeurCloturer" checked={this.state.config.GRP_esellerLeadMobileVendeurCloturer} onChange={this.onChangeConfig.bind(this)} />
                                &nbsp; Pouvoir clôturer une affaire
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" name="GRP_esellerLeadMobileChefVentesReporter" checked={this.state.config.GRP_esellerLeadMobileChefVentesReporter} onChange={this.onChangeConfig.bind(this)} />
                                &nbsp; Pouvoir reporter une affaire
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" name="GRP_esellerLeadMobileVendeurReporter" checked={this.state.config.GRP_esellerLeadMobileVendeurReporter} onChange={this.onChangeConfig.bind(this)} />
                                &nbsp; Pouvoir reporter une affaire
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" name="GRP_esellerLeadMobileChefVentesGagnee" checked={this.state.config.GRP_esellerLeadMobileChefVentesGagnee} onChange={this.onChangeConfig.bind(this)} />
                                &nbsp; Pouvoir passer une affaire en gagné
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" name="GRP_esellerLeadMobileVendeurGagnee" checked={this.state.config.GRP_esellerLeadMobileVendeurGagnee} onChange={this.onChangeConfig.bind(this)} />
                                &nbsp; Pouvoir passer une affaire en gagné
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col-3"></div>
                          <div className="col">
                            <div className="form-check">
                              <label className="form-check-label">
                                <input className="form-check-input" type="checkbox" name="GRP_esellerLeadMobileChefVentesCreerLead" checked={this.state.config.GRP_esellerLeadMobileChefVentesCreerLead} onChange={this.onChangeConfig.bind(this)} />
                                &nbsp; Chef des ventes : pouvoir créer un lead
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col"></div>
                    </div>

                    {this.state.updatedConfig ?
                      <div className="row mb-2" style={{ marginTop: '15px' }}>
                        <div className="col text-center">
                          <button disabled={this.state.fetchInProgressConfig} type="submit" className="btn btn-primary">
                            {this.state.fetchInProgressConfig ?
                              <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                            : null}
                            Enregistrer
                          </button>
                        </div>
                      </div>
                    : null}

                  </form>
                </div>
              </div>
              <div className="tab-pane fade" id="sites" role="tabpanel" aria-labelledby="sites-tab">
                <EsellerLeadSites />
              </div>
              <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
                <EsellerLeadUtilisateurs />
              </div>
              <div className="tab-pane fade" id="synthese" role="tabpanel" aria-labelledby="synthese-tab">
                <EsellerLeadSynthese />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
