import React, { Component } from 'react';
import Utils from '../../Utils';
import { Link } from 'react-router-dom';

class CustomerAccessMenu extends Component {
    render() {
        return (
            <>
                <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Sociétés
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/societes' className="dropdown-item">Sociétés</Link>
                            <Link to='/sites' className="dropdown-item">Sites</Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Utilisateurs
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/utilisateurs' className="dropdown-item">Utilisateurs</Link>
                            {!Utils.isNissan() ?
                                [
                                    <Link to='/profils/eSellerTeam' className="dropdown-item" key="profils_eseller">Profils eSeller</Link>,
                                    <Link to='/utilisateurs/eseller' className="dropdown-item" key="gestion_comptes_eseller">Gestion des comptes eSeller</Link>
                                ]
                                : null}
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Interfaces
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/interfaces/wally' className="dropdown-item">Robot Wally</Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Passerelles
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/passerelles' className="dropdown-item">Passerelles</Link>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Configuration
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <Link to='/lignesFrequentes' className="dropdown-item">Lignes fréquentes</Link>
                            <Link to='/codesOP' className="dropdown-item">Codes OP</Link>
                            <Link to='/OriginesContact' className="dropdown-item">Origines contact</Link>
                            <Link to='/table/SITFAM' className="dropdown-item">Situations familiale</Link>
                            <Link to='/cartesDeFidelite' className="dropdown-item">Cartes de fidélité</Link>
                            <Link to='/fonctions' className="dropdown-item">Fonctions</Link>
                            <Link to='/relances' className="dropdown-item">Relances</Link>
                            <Link to='/objectifsVendeur' className="dropdown-item">Objectifs vendeur (coefficients)</Link>
                            <Link to='/libellesRemise' className="dropdown-item">Libellés remises</Link>
                            <Link to='/plafondsRemise' className="dropdown-item">Plafonds de remise</Link>
                            <Link to='/workflowsLeads' className="dropdown-item">Workflows leads</Link>
                        </div>
                    </li>
                </ul>
                <ul className="navbar-nav float-lg-right mr-3">
                    <li className="nav-item">
                        <a className="nav-link" href="/docs/documentation.pdf" target="_blank">Documentation</a>
                    </li>
                </ul>
                <ul className="navbar-nav pull-right view-selection">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-database" aria-hidden="true"></i> {Utils.fromJWT('name')} ({Utils.fromJWT('BDGp')})
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <a href="#" onClick={() => this.props.onClickDisconnect()} className="dropdown-item">Déconnexion</a>
                        </div>
                    </li>
                </ul>
            </>
        )
    }
}

export default CustomerAccessMenu