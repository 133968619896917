import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BackOfficeAPIClient from '../../../clients/BackofficeAPIClient';

export default function InterfaceEDVMarketing() {
  const backofficeApiClient = new BackOfficeAPIClient()

  const [fetching, setFetching] = useState<boolean>(true)
  const [creating, setCreating] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [edvMarketingUsers, setEdvMarketingUsers] = useState<any[]>([])

  useEffect(() => {
    fecthEDVMarketingUser()
  }, [])

  const fecthEDVMarketingUser = async () => {
    setFetching(true)
    try {
      setEdvMarketingUsers((await backofficeApiClient.get<any[]>("/interfaces/edvMarketing/utilisateurs")))
    }
    catch (error: any) {
      console.log(error)
      setError(error.message)
    }
    finally {
      setFetching(false)
    }
  }

  const createEDVMarketingUser = async () => {
    setCreating(true)
    try {
      await backofficeApiClient.post("/interfaces/edvMarketing/utilisateurs", {})
      window.location.reload()
    }
    catch (error: any) {
      console.log(error)
      setError(error.message)
    }
    finally {
      setCreating(false)
    }
  }

  const confirmCreateEDVMarketingUser = async () => {
    if(confirm("Créer l'utilisateur eDataVoice Marketing ?")) {
      await createEDVMarketingUser()
    }
  }

  return (
    <div className='container-fluid mb-2'>
      <div className="row">
        <div className="col">
          <h2 className="mb-4">eDataVoice Marketing</h2>
          {fetching ?
            <div className="d-flex flex-col align-items-center justify-content-center">
              <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
            </div>
            : error ?
              <div className="d-flex flex-column align-items-center justify-content-center">
                <i className='fa fa-warning'></i>
                {error}
              </div>
              :
              <div>
                <div className='alert alert-info'>
                  La création d'un utilisateur est nécessaire pour qu'eDataVoice Marketing puisse communiquer avec eDataVoice CRM (PRC).<br />
                  <br />
                  Cette communication permet de :
                  <ul className='m-0'>
                    <li>Créer des prospects</li>
                    <li>Mettre à jour des prospects</li>
                    <li>Fusionner des prospects</li>
                  </ul>
                </div>
                <div className='alert alert-warning'>
                  <i className='fa fa-warning mr-1'></i>
                  Une fois l'utilisateur créé, les informations doivent être reportées dans la configuration d'eDataVoice Marketing.
                </div>
                {edvMarketingUsers.length > 0 ?
                  <>
                    <h5>Utilisateur{edvMarketingUsers.length > 1 ? "s": ""} eDataVoice Marketing</h5>
                    <table className="table table-striped table-hover table-sm">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nom</th>
                          <th>Login</th>
                          <th>Mot de passe</th>
                          <th>Profil eDataVoice CRM (PRC)</th>
                          <th>Actif</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {edvMarketingUsers.map(u => {
                          return (
                            <tr key={u.IDPeople}>
                              <td>{u.IDPeople}</td>
                              <td>{u.Name}</td>
                              <td>{u.LoginWEB}</td>
                              <td>{u.Password}</td>
                              <td>{u.Profil_Libelle}</td>
                              <td>{u.Actif ? <span className='text-success font-bold'>Oui</span> : <span className='text-danger font-bold'>Non</span>}</td>
                              <td className="text-right">
                                <Link to={'/utilisateurs/' + u.IDPeople} className='btn btn-primary btn-sm'>
                                  <i className="fa fa-pencil"></i>
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </>
                  :
                  <div className='d-flex justify-content-center align-items-center'>
                    <button type="button" className="btn btn-primary m-2" onClick={confirmCreateEDVMarketingUser}>
                      {creating ?
                        <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                      : null}
                      Créer l'utilisateur eDataVoice Marketing
                    </button>
                  </div>
                }
              </div>
          }
        </div>
      </div>
    </div>
  );
}