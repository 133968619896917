
export interface ActivitePublicationConfig {
    IDActivite: number,
    LibelleActivite: string,
    Actif: boolean,
    LieuStockage?: string,
    LienVideoActif: boolean,
    Lien360Actif: boolean
    Annonceurs: number[]
}

export interface PublicationGpConfig {
    GRP_PublicationVehicules: boolean
}

export interface Annonceur {
    IDAnnonceur: number,
    Nom: string,
    NbPhotosMax: number
    IDActivite: number
}

export enum LieuStockage {
    PROPRIETAIRE = "Site propriétaire",
    STOCKAGE = "Site de stockage"
}

export class PublicationSubscription {
    IDAbonnement!: number
    IDAnnonceur!: number
    Identifiant!: string
    NbVehiculesMax!: number
    Annonceur?: Annonceur
    IDActivite!:number

    constructor(init?: Partial<PublicationSubscription>) {
        Object.assign(this, init)
    }
}

export class SiteAbonnement {
    IDSite!: number
    IDAbonnement!: number
    EmailTraceur?: string
    TelTraceur?: string
    PublicationAutoVN!: boolean
    PublicationAutoVO!: boolean
    PublicationAutoVD!: boolean
    LibelleSite!: string
    PlaqueIDActivite!: number
    SiteIDActivite?: number
    IDAnnonceur!:number

}
export class SiteAbonnementDTO {
    IDSite?: number
    IDAbonnement?: number
    EmailTraceur?: string
    TelTraceur?: string
    PublicationAutoVN!: boolean
    PublicationAutoVO!: boolean
    PublicationAutoVD!: boolean
    IDAnnonceur!:number

    constructor(init?: Partial<SiteAbonnement>) {
        Object.assign(this, init)
        this.PublicationAutoVN = false
        this.PublicationAutoVO = false
        this.PublicationAutoVD = false
    }
}