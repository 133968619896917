import React, { Component } from 'react'
import Select from 'react-select'

class MarquesRefLoisir extends Component {

    constructor(props) {
        super(props);

        this.state = {
            marquesOptList: [],
            selectedMarqueCodes: [],
            regenerateToken: false,
        }
    }

    componentDidMount() {
        this.fetchMarques();
        this.fetchActivatedMarques();
        window.$('[data-toggle="tooltip"]').tooltip();
    }

    async fetchMarques() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/refLoisir/brands', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                }
            })

            if (!response.ok) {
                throw Error(response.statusText);
            }

            const marquesOptList = (await response.json()).brands.map(m => {
                return {
                    "value": m.code,
                    "label": m.name
                }
            })

            this.setState({
                marquesOptList: marquesOptList
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    async fetchActivatedMarques() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/refLoisir/groupBrands', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                }
            })

            if (!response.ok) {
                throw Error(response.statusText);
            }
            const selected = (await response.json()).brands.map(m => m.code)
            this.setState({
                selectedMarqueCodes: selected
            })

            this.props.updateRefLoisirSelectedMarques(selected)
        }
        catch (error) {
            console.log(error)
        }
    }

    async submit() {
        const response = await fetch(localStorage.backoffice_api + '/api/refLoisir/syncGroup', {
            method: 'POST',
            headers: {
                authorization: localStorage.JWT,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "brands": this.state.selectedMarqueCodes,
                "regenerate_token": this.state.regenerateToken
            })
        })

        if (!response.ok) {
            throw Error(response.statusText);
        }
    }

    onChangeMarqueSelection(items) {
        this.setState({
            selectedMarqueCodes: items.map(i => i.value)
        }, () => {
            if (this.props.onChangeMarques) {
                this.props.onChangeMarques(this.state.selectedMarqueCodes)
            }
        })
    }

    onChangeRegenerateToken(event) {
        this.setState({
            regenerateToken: event.target.checked
        }, () => {
            if (this.props.onChangeRegenerateToken) {
                this.props.onChangeRegenerateToken(this.state.regenerateToken)
            }
        })
    }

    render() {
        return (
            <div className="card mb-3">
                <div className="card-body p-3">
                    <h6 className="card-title">Marques actives sur le Référentiel Loisir</h6>
                    <Select multi
                        style={{ minWidth: '30em' }}
                        className="d-inline-block"
                        onChange={this.onChangeMarqueSelection.bind(this)}
                        value={this.state.selectedMarqueCodes}
                        options={this.state.marquesOptList}
                        searchable={true}
                        placeholder='Aucune marque active'
                    />
                    <div className="form-check">
                        <label className="form-check-label">
                            <input className="form-check-input" type="checkbox" name="regenerate_token" checked={this.state.regenerateToken} onChange={this.onChangeRegenerateToken.bind(this)} />
                            Regénérer le token
                            <i className="fa fa-exclamation-triangle text-danger ml-2" data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
                                title="Attention les utilisateurs devront se déconnecter et se reconnecter du Poste Relation Client pour que l'accès au Référentiel Loisir fonctionne !<br/>Cette option ne devrait être cochée que si le token précédemment généré a été perdu ou compromis.">
                            </i>
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}


export default MarquesRefLoisir