import React, { Component } from 'react'
import MarquesRefMoto from './MarquesRefMoto';
import MarquesRefLoisir from './MarquesRefLoisir';

const lodash = require('lodash')

class Catalogues extends Component {
    catalogColumnNames = [
        "AutovistaActif",
        "RefMotoActif",
        "RefVdlActif"
    ]

    marquesRefMotoRef = React.createRef()
    marquesRefLoisirRef = React.createRef()

    constructor(props) {
        super(props);

        this.state = {
            sites: [],
            updatedSiteIds: [],
            submitting: false,
            refMotoSelectedMarqueCodes: [],
            refMotoSelectedMarqueCodesUpdated: false,
            refLoisirSelectedMarqueCodes: [],
            refLoisirSelectedMarqueCodesUpdated: false,
        }
    }

    componentDidMount() {
        this.fetchSites();
    }

    async fetchSites() {
        try {
            const response = await fetch(localStorage.backoffice_api + '/api/sites', {
                method: 'GET',
                headers: {
                    authorization: localStorage.JWT,
                }
            })

            if (!response.ok) {
                throw Error(response.statusText);
            }

            let sites = await response.json();
            sites = lodash.sortBy(sites, (site) => {
                return site.Plaque_Libelle
            })

            this.setState({
                sites: sites
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    onChange(IDSite, catalogColumnName) {
        if (!this.catalogColumnNames.includes(catalogColumnName)) {
            return;
        }

        let sites = this.state.sites
        let idx = lodash.findIndex(this.state.sites, (site) => site.IDSite == IDSite)

        if (!idx === -1)
            return;

        sites[idx][catalogColumnName] = !sites[idx][catalogColumnName]

        let updatedSiteIds = this.state.updatedSiteIds
        if (!updatedSiteIds.includes(IDSite)) {
            updatedSiteIds.push(IDSite)
        }

        this.setState({
            updatedSiteIds: updatedSiteIds,
            sites: sites
        })
    }

    async onSubmit() {
        if (this.refMotoActif() && this.state.refMotoSelectedMarqueCodes.length === 0) {
            alert("Le Référentiel Moto est activé pour au moins un site : vous devez configurer au moins une marque")
            return
        }
        if (this.refLoisirActif() && this.state.refLoisirSelectedMarqueCodes.length === 0) {
            alert("Le Référentiel Loisir est activé pour au moins un site : vous devez configurer au moins une marque")
            return
        }

        this.setState({
            submitting: true
        })

        try {
            const updatedSitesData = lodash.filter(this.state.sites, s => this.state.updatedSiteIds.includes(s.IDSite)).map(s => {
                return {
                    "IDSite": s.IDSite,
                    "AutovistaActif": s.AutovistaActif,
                    "RefMotoActif": s.RefMotoActif,
                    "RefVdlActif": s.RefVdlActif
                }
            })
            const response = await fetch(localStorage.backoffice_api + '/api/sites/catalogues', {
                method: 'PATCH',
                headers: {
                    authorization: localStorage.JWT,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "sites": updatedSitesData
                })
            })

            if (!response.ok) {
                throw Error(response.statusText);
            }

            this.setState({
                updatedSiteIds: []
            })

            if (this.refMotoActif()) {
                try {
                    await this.marquesRefMotoRef.current.submit()
                    this.setState({
                        refMotoSelectedMarqueCodesUpdated: false
                    })
                }
                catch (error) {
                    alert("Erreur lors de la synchronisation des marques actives sur le Référentiel Moto")
                    console.log(error)
                }
            }

            if (this.refLoisirActif()) {
                try {
                    await this.marquesRefLoisirRef.current.submit()
                    this.setState({
                        refLoisirSelectedMarqueCodesUpdated: false
                    })
                }
                catch (error) {
                    alert("Erreur lors de la synchronisation des marques actives sur le Référentiel Loisir")
                    console.log(error)
                }
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            this.setState({
                submitting: false
            })
        }
    }

    refMotoActif() {
        return !!lodash.find(this.state.sites, s => s.RefMotoActif)
    }

    refLoisirActif() {
        return !!lodash.find(this.state.sites, s => s.RefVdlActif)
    }

    onChangeRefMotoSelectedMarques(selectedMarqueCodes) {
        this.setState({
            refMotoSelectedMarqueCodes: selectedMarqueCodes,
            refMotoSelectedMarqueCodesUpdated: true
        })
    }

    onChangeRefLoisirSelectedMarques(selectedMarqueCodes) {
        this.setState({
            refLoisirSelectedMarqueCodes: selectedMarqueCodes,
            refLoisirSelectedMarqueCodesUpdated: true
        })
    }

    updateRefMotoSelectedMarques(selectedMarqueCodes) {
        this.setState({
            refMotoSelectedMarqueCodes: selectedMarqueCodes,
        })
    }

    updateRefLoisirSelectedMarques(selectedMarqueCodes) {
        this.setState({
            refLoisirSelectedMarqueCodes: selectedMarqueCodes,
        })
    }


    render() {
        return (
            <div className='container-fluid mb-2'>
                <div className='row'>
                    <div className='col'>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <h2>Catalogues</h2>
                            <div>
                                {this.state.updatedSiteIds.length > 0 || this.state.refMotoSelectedMarqueCodesUpdated || this.state.refLoisirSelectedMarqueCodesUpdated ?
                                    <div className="d-flex flex-row">
                                        <div className="alert alert-warning m-0 p-1 mr-1">
                                            <i className="fa fa-warning mr-1"></i>
                                            Modifications non sauvegardées
                                        </div>
                                        <button type="submit" className="btn btn-primary m-0 p-1" onClick={this.onSubmit.bind(this)}>
                                            {this.state.submitting && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                                            Valider
                                        </button>
                                    </div>
                                    : null}
                            </div>
                        </div>

                        <div className="alert alert-danger" role="alert"><h4 className="alert-heading">Attention !</h4>L'activation d'un site est soumis à facturation, merci de vous rapprocher du service commerce si le client souhaite faire évoluer sa configuration.</div>

                        <div className='d-flex'>
                            {this.refMotoActif() &&
                                <MarquesRefMoto ref={this.marquesRefMotoRef}
                                    onChangeMarques={this.onChangeRefMotoSelectedMarques.bind(this)}
                                    updateRefMotoSelectedMarques={this.updateRefMotoSelectedMarques.bind(this)}
                                />}
                            {this.refLoisirActif() &&
                                <MarquesRefLoisir ref={this.marquesRefLoisirRef}
                                    onChangeMarques={this.onChangeRefLoisirSelectedMarques.bind(this)}
                                    updateRefLoisirSelectedMarques={this.updateRefLoisirSelectedMarques.bind(this)}
                                />}

                        </div>

                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Société</th>
                                    <th scope="col">Site</th>
                                    <th scope="col" className="text-center">Autovista</th>
                                    <th scope="col" className="text-center">Référentiel Moto</th>
                                    <th scope="col" className="text-center">Référentiel VDL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sites.map((site) => {
                                    return (<tr key={site.IDSite}>
                                        <td>{site.Plaque_Libelle}</td>
                                        <td>{site.Descr}</td>
                                        <td className="text-center">
                                            <input onChange={this.onChange.bind(this, site.IDSite, "AutovistaActif")} type="checkbox" checked={site.AutovistaActif == 1} />
                                        </td>
                                        <td className="text-center">
                                            <input onChange={this.onChange.bind(this, site.IDSite, "RefMotoActif")} type="checkbox" checked={site.RefMotoActif == 1} />
                                        </td>
                                        <td className="text-center">
                                            <input onChange={this.onChange.bind(this, site.IDSite, "RefVdlActif")} type="checkbox" checked={site.RefVdlActif == 1} />
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>

                        <div className="row mt-4">
                            <div className="col text-center">
                                <button disabled={this.state.updatedSiteIds.length === 0 && !this.state.refMotoSelectedMarqueCodesUpdated && !this.state.refLoisirSelectedMarqueCodesUpdated} type="submit" className="btn btn-primary" onClick={this.onSubmit.bind(this)}>
                                    {this.state.submitting && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                                    Valider
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Catalogues