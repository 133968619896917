import React, { Component } from 'react';

class Form extends Component {

	constructor(props) {
		super(props);

		this.state = {
			categorieClient: null,
			updated: false,
			fetchInProgress: false,
			fetchValue: false,
			typesFicheClient: []
		}
	}

	componentWillMount() {

		this.fetchTypesFicheClient()

		if (this.props.match.params.id) {
			this.fetchValue()
		}
		else {
			this.setState({
				categorieClient: {
					"Descr": '',
					"EstActif": true,
					"IDTFicheCli": 1
				}
			})
		}
	}

	componentDidUpdate() {
		if (this.props.match.params.id && !this.state.fetchValue) {
			this.fetchValue()
		}
	}

	fetchTypesFicheClient() {
		fetch(localStorage.backoffice_api + '/api/typesFicheClient', {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					typesFicheClient: json,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	fetchValue() {

		this.setState({
			fetchValue: true,
		})

		fetch(localStorage.backoffice_api + '/api/table/CategorieClient/' + this.props.match.params.id, {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					categorieClient: json,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	onChange(event) {
		var type = event.target.type
		var name = event.target.name
		var value = event.target.value

		var object = this.state.categorieClient

		if (type === 'checkbox') {
			object[name] = event.target.checked
		}
		else {
			object[name] = value
		}

		this.setState({
			updated: true,
			categorieClient: object,
		})
	}

	handleSubmit(event) {
		event.preventDefault()

		this.setState({
			fetchInProgress: true,
		})

		if (!this.props.match.params.id) {
			this.createValue();
		}
		else {
			this.editValue();
		}
	}

	createValue() {
		fetch(localStorage.backoffice_api + '/api/table/CategorieClient', {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.JWT,
			},
			body: JSON.stringify(this.state.categorieClient)
		})
			.then((response) => {
				if (!response.ok) {
					response.json().then((value) => {
						alert(value.message)
					});
					this.setState({
						fetchInProgress: false,
					})
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					updated: false,
					fetchInProgress: false,
				}, () => {
					this.props.history.push('/table/CategorieClient/' + json.IDCatClient)
				})

			})
			.catch((error) => {
				console.log(error)
			});
	}

	editValue() {

		fetch(localStorage.backoffice_api + '/api/table/CategorieClient/' + this.props.match.params.id, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.JWT,
			},
			body: JSON.stringify(this.state.categorieClient)
		})
			.then((response) => {
				if (!response.ok) {
					response.json().then((value) => {
						alert(value.message)
					});
					this.setState({
						fetchInProgress: false,
					})
					throw Error(response);
				}

				this.setState({
					updated: false,
					categorieClient: null,
					fetchInProgress: false,
				}, () => {
					this.fetchValue()
				})
			})
			.catch((error) => {
				console.log(error)
			});
	}

	render() {

		if (!this.state.categorieClient) {
			return null;
		}

		return (
			<div className='container-fluid'>
				<h2>Catégorie client</h2>
				<br />
				<form onSubmit={this.handleSubmit.bind(this)}>
					<div className="row">
						<div className="col">
							<div className="form-group row">
								<label htmlFor="Descr" className="col-sm-3 col-form-label">Type fiche client</label>
								<div className="col-sm-6">
									<select required='required' value={this.state.categorieClient.IDTFicheCli ? this.state.categorieClient.IDTFicheCli : ''} onChange={this.onChange.bind(this)} id='IDTFicheCli' name="IDTFicheCli" className="form-control form-control-sm">
										{this.state.typesFicheClient.map((typeFicheClient, i) => {
											return <option key={i} value={typeFicheClient.IDTFicheCli}>{typeFicheClient.Descr}</option>
										})}
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="Descr" className="col-sm-3 col-form-label">Libellé</label>
								<div className="col-sm-6">
									<input required='required' type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.categorieClient.Descr} onChange={this.onChange.bind(this)}/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="Descr" className="col-sm-3 col-form-label">Est actif</label>
								<div className="col-sm-6">
									<input className="form-check-input" type="checkbox" name="EstActif" checked={this.state.categorieClient.EstActif} onChange={this.onChange.bind(this)} />
								</div>
							</div>
						</div>
						<div className="col"></div>
					</div>

					{this.state.updated ?
						<div className="row" style={{ marginTop: '15px' }}>
							<div className="col text-center">
								<button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
									{this.state.fetchInProgress ?
										<span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
										: null}
									Enregistrer
								</button>
							</div>
						</div>
						: null}
				</form>
			</div>
		);
	}
}

export default Form;
