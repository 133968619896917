import React, { Component } from 'react';

import Select from 'react-select';
import Utils from '../../Utils';
import FormDemande from '../FormDemande/Form'

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      site: null,
      societes: [],
      activites: [],
      marques: [],
      selectedMarques: '',
      types: [],
      passerelles: [],
      products: [],
      updated: false,
      fetchInProgress: false,
      fetchSite: false,
    }
  }

  componentWillMount() {

    if (this.props.match.params.id) {
      this.fetchSite()
      this.fetchSiteMarques()
    }
    else {
      this.setState({site: {
        'IDActivite': null,
        'ActiveIsSociete':true,
        'Descr': '',
        'Libelle': '',
        'Code': '',
        'CodeConstructeur': '',
        'Address1': '',
        'Address2': '',
        'CodePostal': '',
        'Ville': '',
        'Tel': '',
        'Fax': '',
        'EMail': '',
        'IDPlaque': '',
        'IDTypeSite': '',
        'IDPasserelleDMS': '',
        'TPR_IDTypeProduit': '',
        'IDWelcomePro': '',
        'CodeRegionConstructeur': '',
        'CodeSiteBaseVN': '',
        'Est_eSellerLeadActif': false,
        'EstVO': false,
        'EstVN': false,
        'EstFictif': false,
        'ImprHautPage': false,
        'ImprBasPage': false,
        'RDVsAtelierSurMobile': false
      }})
    }
    this.fetchSocietes()
    this.fetchTypes()
    this.fetchPasserelles()
    this.fetchProducts()
    this.fetchActivites()
  }

  componentDidUpdate() {
    if (this.props.match.params.id && !this.state.fetchSite) {
      this.fetchSite()
      this.fetchSiteMarques()
    }
  }

  fetchSite() {

    this.setState({
      fetchSite: true,
    })

    fetch(localStorage.backoffice_api + '/api/sites/'+this.props.match.params.id, {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      json.ActiveIsSociete = false
      if(json.IDActivite == null){
        json.ActiveIsSociete = true
      }
      console.log(json)

      this.setState({
        site: json,
      }, () => {
        this.fetchMarques()
        window.$('[data-toggle="tooltip"]').tooltip();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSiteMarques() {
    fetch(localStorage.backoffice_api + '/api/sites/'+this.props.match.params.id + '/marques', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var marques = json.map((marque) => {
        return marque.Make
      }).join(',');

      this.setState({
        selectedMarques: marques,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchMarques() {
    if (!this.state.site || !this.state.site.IDPlaque) {
      return;
    }

    fetch(localStorage.backoffice_api + '/api/societes/'+this.state.site.IDPlaque+'/marques', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var _marques = json.map((m) => {
        return {value: m.Make, label: m.Make}
      });

      this.setState({
        marques: _marques,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchSocietes() {
    fetch(localStorage.backoffice_api + '/api/societes/', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        societes: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchTypes() {
    fetch(localStorage.backoffice_api + '/api/sites/types/', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        types: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchActivites() {
    fetch(localStorage.backoffice_api + '/api/activites/', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        activites: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchPasserelles() {
    fetch(localStorage.backoffice_api + '/api/passerelles', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        passerelles: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchProducts() {
    fetch(localStorage.backoffice_api + '/api/bdref3/products', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        products: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChange(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var site = this.state.site

    if (type === 'checkbox') {
      site[name] = event.target.checked
    }
    else if (type === 'file') {
      let reader = new FileReader();
      reader.onload = function(e) {
          site[name] = reader.result;
          this.setState({
            updated: true,
            site: site,
          });
      }
      reader.readAsDataURL(event.target.files[0]);
    }
    else {
      site[name] = value
    }

    this.setState({
      updated: true,
      site: site,
    }, () => {
      if (name === 'IDPlaque') {
        this.fetchMarques()
      }
    })
  }

  handleSubmit(event) {
    event.preventDefault()

    this.setState({
      fetchInProgress: true,
    })

    if (!this.props.match.params.id) {
      this.createSite();
    }
    else {
      this.editSite();
    }
  }

  createSite() {
    fetch(localStorage.backoffice_api + '/api/sites/', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.site)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      fetch(localStorage.backoffice_api + '/api/sites/'+this.props.match.params.id + '/marques', {
        method: 'PUT',
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.JWT,
        },
        body: JSON.stringify(this.state.selectedMarques.split(','))
      })
      .then((response) => {
        if (!response.ok) {
          response.json().then((value) => {
            alert(value.message)
          });
          this.setState({
            fetchInProgress: false,
          })
          throw Error(response);
        }

        this.setState({
          site: null,
          updated: false,
          fetchInProgress: false,
        }, () => {
          this.props.history.push('/sites/' + json.IDSite)
        })
      })
      .catch((error) => {
        console.log(error)
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  editSite() {
    fetch(localStorage.backoffice_api + '/api/sites/'+this.props.match.params.id, {
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.JWT,
      },
      body: JSON.stringify(this.state.site)
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          alert(value.message)
        });
        this.setState({
          fetchInProgress: false,
        })
        throw Error(response);
      }

      if(!Utils.isAccesClient()) {
        fetch(localStorage.backoffice_api + '/api/sites/'+this.props.match.params.id + '/marques', {
          method: 'PUT',
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.JWT,
          },
          body: JSON.stringify(this.state.selectedMarques.split(','))
        })
        .then((response) => {
          if (!response.ok) {
            response.json().then((value) => {
              alert(value.message)
            });
            this.setState({
              fetchInProgress: false,
            })
            throw Error(response);
          }

          this.setState({
            updated: false,
            site: null,
            fetchInProgress: false,
          }, () => {
            this.fetchSite()
            this.fetchSiteMarques()
          })
        })
        .catch((error) => {
          console.log(error)
        });
      }
      else {
        this.setState({
          updated: false,
          site: null,
          fetchInProgress: false,
        }, () => {
          this.fetchSite()
          this.fetchSiteMarques()
        })
      }
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangeMarques(values) {
    var marques = values.map((value) => {
      return value.value
    }).join(',');

    this.setState({
      selectedMarques: marques,
      updated: true,
    })
  }

  renderHautDePageOffre(){
    return (
       [<div className="row" key="1">
          <div className="col">
          <h4>Haut de page Offre</h4>
            <div className="row">
              <div className="col">
                <div className="form-check">
                  <label className="form-check-label">
                    <input className="form-check-input" type="checkbox" name="ImprHautPage" checked={this.state.site.ImprHautPage} onChange={this.onChange.bind(this)} />
                    &nbsp; Imprime haut de page
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col"></div>
        </div>,

        this.state.site.ImprHautPage ?
          <div className="row" key="2">
            <div className="col">
              <div className="form-group row">
                <label htmlFor="EnTete1" className="col-sm-3 col-form-label">En tête 1</label>
                <div className="col-sm-6">
                  <input type="text" className="form-control form-control-sm" id="EnTete1" name="EnTete1" value={this.state.site.EnTete1 ? this.state.site.EnTete1 : ''} onChange={this.onChange.bind(this)} maxLength="100" />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="EnTete2" className="col-sm-3 col-form-label">En tête 2</label>
                <div className="col-sm-6">
                  <textarea className='form-control' id="EnTete2" name="EnTete2" value={this.state.site.EnTete2 ? this.state.site.EnTete2 : ''} onChange={this.onChange.bind(this)} rows="6" />
                </div>
              </div>
            </div>

            <div className="col">
              <div className="form-group row">
                <label htmlFor="EnTeteImg" className="col-sm-3 col-form-label">En tête Image (PNG)</label>
                <div className="col-sm-6">
                  {this.state.site.EnTeteImg ?
                    <div>
                      <img src={'http://'+this.state.site.urlImage+'/ImagesMaterial/'+this.state.site.folderImage+'/1/'+this.state.site.EnTeteImg} />
                    </div>
                  : null}


                  <input type='file' className='form-control' id="EnTeteImgBinary" name="EnTeteImgBinary" onChange={this.onChange.bind(this)} />
                </div>
              </div>
            </div>
          </div>
        : null
    ])
  }

  renderBasDePageOffre(){
    return(
      [
        <div className="row" key="1">
        <div className="col">
                <h4>Bas de page Offre</h4>
                <div className="row">
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="ImprBasPage" checked={this.state.site.ImprBasPage} onChange={this.onChange.bind(this)} />
                        &nbsp; Imprime bas de page
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col"></div>
            </div>,

            this.state.site.ImprBasPage ?
            
            <div className="row" key="2" style={{marginLeft: 20}}>
                <div className="col">
                  <div className="form-group row">
                    <div className="col-sm-3">
                      <div className="row">
                        <label htmlFor="BasPageCol1" className="col-form-label">Bas de page 1</label>
                      </div>
                      <div className="row">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" name="BasPageCol1EstImg" checked={this.state.site.BasPageCol1EstImg} onChange={this.onChange.bind(this)} />
                            Est une image ?
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                    {this.state.site.BasPageCol1EstImg ?
                      <div>
                        {this.state.site.BasPageCol1 != '' ?
                        <div>
                          <img src={'http://'+this.state.site.urlImage+'/ImagesMaterial/'+this.state.site.folderImage+'/1/'+this.state.site.BasPageCol1} />
                        </div>
                        :null}
                        <input type='file' className='form-control' id="BasPageCol1" name="BasPageCol1" onChange={this.onChange.bind(this)} />
                      </div>
                    : 
                      <textarea className='form-control' id="BasPageCol1" name="BasPageCol1" value={this.state.site.BasPageCol1 ? this.state.site.BasPageCol1 : ''} onChange={this.onChange.bind(this)} rows="6" />
                    }

                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-3">
                      <div className="row">
                        <label htmlFor="BasPageCol2" className="col-form-label">Bas de page 2</label>
                      </div>
                      <div className="row">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" name="BasPageCol2EstImg" checked={this.state.site.BasPageCol2EstImg} onChange={this.onChange.bind(this)} />
                            Est une image ?
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                    {this.state.site.BasPageCol2EstImg ?
                      <div>
                        {this.state.site.BasPageCol2 != '' ?
                        <div>
                          <img src={'http://'+this.state.site.urlImage+'/ImagesMaterial/'+this.state.site.folderImage+'/1/'+this.state.site.BasPageCol2} />
                        </div>
                        :null}
                        <input type='file' className='form-control' id="BasPageCol2" name="BasPageCol2" onChange={this.onChange.bind(this)} />
                      </div>
                    : 
                      <textarea className='form-control' id="BasPageCol2" name="BasPageCol2" value={this.state.site.BasPageCol2 ? this.state.site.BasPageCol2 : ''} onChange={this.onChange.bind(this)} rows="6" />
                    }

                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-3">
                      <div className="row">
                        <label htmlFor="BasPageCol3" className="col-form-label">Bas de page 3</label>
                      </div>
                      <div className="row">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" name="BasPageCol3EstImg" checked={this.state.site.BasPageCol3EstImg} onChange={this.onChange.bind(this)} />
                            Est une image ?
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                    {this.state.site.BasPageCol3EstImg ?
                      <div>
                        {this.state.site.BasPageCol3 != '' ?
                        <div>
                          <img src={'http://'+this.state.site.urlImage+'/ImagesMaterial/'+this.state.site.folderImage+'/1/'+this.state.site.BasPageCol3} />
                        </div>
                        :null}
                        <input type='file' className='form-control' id="BasPageCol3" name="BasPageCol3" onChange={this.onChange.bind(this)} />
                      </div>
                    : 
                      <textarea className='form-control' id="BasPageCol3" name="BasPageCol3" value={this.state.site.BasPageCol3 ? this.state.site.BasPageCol3 : ''} onChange={this.onChange.bind(this)} rows="6" />
                    }

                    </div>
                  </div>
                </div>

                <div className="col">
                <div className="form-group row">
                    <div className="col-sm-3">
                      <div className="row">
                        <label htmlFor="BasPageCol4" className="col-form-label">Bas de page 4</label>
                      </div>
                      <div className="row">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" name="BasPageCol4EstImg" checked={this.state.site.BasPageCol4EstImg} onChange={this.onChange.bind(this)} />
                            Est une image ?
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                    {this.state.site.BasPageCol4EstImg ?
                      <div>
                        {this.state.site.BasPageCol4 != '' ?
                        <div>
                          <img src={'http://'+this.state.site.urlImage+'/ImagesMaterial/'+this.state.site.folderImage+'/1/'+this.state.site.BasPageCol4} />
                        </div>
                        :null}
                        <input type='file' className='form-control' id="BasPageCol4" name="BasPageCol4" onChange={this.onChange.bind(this)} />
                      </div>
                    : 
                      <textarea className='form-control' id="BasPageCol4" name="BasPageCol4" value={this.state.site.BasPageCol4 ? this.state.site.BasPageCol4 : ''} onChange={this.onChange.bind(this)} rows="6" />
                    }

                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-3">
                      <div className="row">
                        <label htmlFor="BasPageCol5" className="col-form-label">Bas de page 5</label>
                      </div>
                      <div className="row">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" name="BasPageCol5EstImg" checked={this.state.site.BasPageCol5EstImg} onChange={this.onChange.bind(this)} />
                            Est une image ?
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                    {this.state.site.BasPageCol5EstImg ?
                      <div>
                        {this.state.site.BasPageCol5 != '' ?
                        <div>
                          <img src={'http://'+this.state.site.urlImage+'/ImagesMaterial/'+this.state.site.folderImage+'/1/'+this.state.site.BasPageCol5} />
                        </div>
                        :null}
                        <input type='file' className='form-control' id="BasPageCol5" name="BasPageCol5" onChange={this.onChange.bind(this)} />
                      </div>
                    : 
                      <textarea className='form-control' id="BasPageCol5" name="BasPageCol5" value={this.state.site.BasPageCol5 ? this.state.site.BasPageCol5 : ''} onChange={this.onChange.bind(this)} rows="6" />
                    }

                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-3">
                      <div className="row">
                        <label htmlFor="BasPageTexteOffre" className="col-form-label">Bas de page texte offre</label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <textarea className='form-control' id="BasPageTexteOffre" name="BasPageTexteOffre" value={this.state.site.BasPageTexteOffre ? this.state.site.BasPageTexteOffre : ''} onChange={this.onChange.bind(this)} rows="6" />
                    </div>
                  </div>
              </div>
            </div>
              
              
            : null
      ]
    )
  }

  render() {

    if (!this.state.site) {
      return null;
    }
    
    if(Utils.isAccesClient()){
      if (!this.props.match.params.id) {
        return this.renderFormDemande();
      }
      else {
        return this.renderAccesClient();
      }
    }

    return (
      <div className='container-fluid'>

          <h2>Sites</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Descr" className="col-sm-3 col-form-label">Nom</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.site.Descr} onChange={this.onChange.bind(this)} maxLength="60" required='required' />
                  </div>
                </div>
              </div>
              <div className="col">
                
                <div className="form-group row">

                    <div className="col-sm-3">
                      <div className="row">
                        <label htmlFor="IDActivite" className="col-sm-3 col-form-label">Activité</label>
                      </div>
                      <div className="row">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" name="ActiveIsSociete" checked={this.state.site.ActiveIsSociete} onChange={this.onChange.bind(this)} />
                            Lié a la société ?
                        </label>
                      </div>
                    </div>
                      <div className="col-sm-6">
                      <select disabled = {(this.state.site.ActiveIsSociete) ? "disabled" : ""}  value={this.state.site.IDActivite} onChange={this.onChange.bind(this)} id='IDActivite' name="IDActivite" className="form-control form-control-sm" required='required'>
                        {this.state.activites.map((activite, i) => {
                          return <option key={i} value={activite.IDActivite}>{activite.Descr}</option>
                        })}
                      </select>
                    </div>
                </div>
              </div>

              
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Code" className="col-sm-3 col-form-label">Code site</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Code" name="Code" value={this.state.site.Code} onChange={this.onChange.bind(this)} maxLength="25" required='required' />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Libelle" className="col-sm-3 col-form-label">Libelle (eSeller Lead)</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Libelle" name="Libelle" value={this.state.site.Libelle ? this.state.site.Libelle : ''} onChange={this.onChange.bind(this)} maxLength="100" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Address1" className="col-sm-3 col-form-label">Adresse</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Address1" name="Address1" value={this.state.site.Address1} onChange={this.onChange.bind(this)} maxLength="40" />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="CodeConstructeur" className="col-sm-3 col-form-label">Code constructeur</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="CodeConstructeur" name="CodeConstructeur" value={this.state.site.CodeConstructeur ? this.state.site.CodeConstructeur : ''} onChange={this.onChange.bind(this)} maxLength="10" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="CodePostal" className="col-sm-3 col-form-label">Code postal</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="CodePostal" name="CodePostal" value={this.state.site.CodePostal} onChange={this.onChange.bind(this)} maxLength="10" />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Address2" className="col-sm-3 col-form-label">Complément d'adresse</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Address2" name="Address2" value={this.state.site.Address2} onChange={this.onChange.bind(this)} maxLength="40" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Tel" className="col-sm-3 col-form-label">Téléphone</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Tel" name="Tel" value={this.state.site.Tel} onChange={this.onChange.bind(this)} maxLength="20" />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Ville" className="col-sm-3 col-form-label">Ville</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Ville" name="Ville" value={this.state.site.Ville} onChange={this.onChange.bind(this)} maxLength="50" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="EMail" className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="EMail" name="EMail" value={this.state.site.EMail ? this.state.site.EMail : ''} onChange={this.onChange.bind(this)} maxLength="255" />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Fax" className="col-sm-3 col-form-label">Fax</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Fax" name="Fax" value={this.state.site.Fax ? this.state.site.Fax : ''} onChange={this.onChange.bind(this)} maxLength="20" />
                  </div>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDPlaque" className="col-sm-3 col-form-label">Société d'appartenance</label>
                  <div className="col-sm-6">
                  {this.props.match.params.id ?
                    this.state.societes.map((societe, i) => {
                      if((this.state.site.IDPlaque && this.state.site.IDPlaque === societe.IDPlaque))
                        return <input key={i} type='text' className="form-control form-control-sm" readOnly='readonly' value={societe.Libelle}/>
                    })
                  : 
                     <select value={this.state.site.IDPlaque ? this.state.site.IDPlaque : ''} onChange={this.onChange.bind(this)} id='IDPlaque' name="IDPlaque" className="form-control form-control-sm" required='required'>
                       {!this.state.site.IDPlaque ? <option value=''></option> : null}
                       {this.state.societes.map((societe, i) => {
                             return <option key={societe.IDPlaque} value={societe.IDPlaque}>{societe.Libelle}</option>
                       })}
                     </select>
                  }
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="EMailsContacts" className="col-sm-3 col-form-label">Marques</label>
                  <div className="col-sm-6">
                  <Select multi={true} name="marques" value={this.state.selectedMarques} options={this.state.marques} searchable={true} placeholder='Marques...' onChange={this.onChangeMarques.bind(this)} />
                  <small className="form-text text-muted">Les marques disponibles sont définies par la société</small>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDPasserelleDMS" className="col-sm-3 col-form-label">Passerelle</label>
                  <div className="col-sm-6">
                    <select value={this.state.site.IDPasserelleDMS ? this.state.site.IDPasserelleDMS : ''} onChange={this.onChange.bind(this)} id='IDPasserelleDMS' name="IDPasserelleDMS" className="form-control form-control-sm">
                      <option value=''></option>
                      {this.state.passerelles.map((passerelle, i) => {
                        return <option key={passerelle.IDPasserelleDMS} value={passerelle.IDPasserelleDMS}>{passerelle.Libelle} - {passerelle.Commentaire}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDTypeSite" className="col-sm-3 col-form-label">Type de site</label>
                  <div className="col-sm-6">
                    <select value={this.state.site.IDTypeSite ? this.state.site.IDTypeSite : ''} onChange={this.onChange.bind(this)} id='IDTypeSite' name="IDTypeSite" className="form-control form-control-sm" required='required'>
                      <option value=''></option>
                      {this.state.types.map((type, i) => {
                        return <option key={type.IDTypeSite} value={type.IDTypeSite}>{type.Descr}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDWelcomePro" className="col-sm-3 col-form-label">IDWelcomePro</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="IDWelcomePro" name="IDWelcomePro" value={this.state.site.IDWelcomePro ? this.state.site.IDWelcomePro : ''} onChange={this.onChange.bind(this)} maxLength="30" />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="TPR_IDTypeProduit" className="col-sm-3 col-form-label">Type de produit</label>
                  <div className="col-sm-6">
                    <select value={this.state.site.TPR_IDTypeProduit ? this.state.site.TPR_IDTypeProduit : ''} onChange={this.onChange.bind(this)} id='TPR_IDTypeProduit' name="TPR_IDTypeProduit" className="form-control form-control-sm">
                      <option value=''></option>
                      {this.state.products.map((product, i) => {
                        return <option key={product.TPR_IDTypeProduit} value={product.TPR_IDTypeProduit}>{product.TPR_Descr}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="CodeSiteBaseVN" className="col-sm-3 col-form-label">CodeSiteBaseVN</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="CodeSiteBaseVN" name="CodeSiteBaseVN" value={this.state.site.CodeSiteBaseVN ? this.state.site.CodeSiteBaseVN : ''} onChange={this.onChange.bind(this)} maxLength="20" />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="CodeRegionConstructeur" className="col-sm-3 col-form-label">CodeRegionConstructeur</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="CodeRegionConstructeur" name="CodeRegionConstructeur" value={this.state.site.CodeRegionConstructeur ? this.state.site.CodeRegionConstructeur : ''} onChange={this.onChange.bind(this)} maxLength="10" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="Est_eSellerLeadActif" checked={this.state.site.Est_eSellerLeadActif} onChange={this.onChange.bind(this)} />
                        &nbsp; Est_eSellerLeadActif
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="EstVN" checked={this.state.site.EstVN} onChange={this.onChange.bind(this)} />
                        &nbsp; EstVN
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="EstVO" checked={this.state.site.EstVO} onChange={this.onChange.bind(this)} />
                        &nbsp; EstVO
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input className="form-check-input" type="checkbox" name="EstFictif" checked={this.state.site.EstFictif} onChange={this.onChange.bind(this)} />
                        &nbsp; EstFictif
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label" data-toggle="tooltip" data-trigger="hover" data-placement="top" title={!this.state.site.Est_eSellerLeadActif ? "Le site doit avoir eSeller Lead activé pour pouvoir cocher cette option" : ""}>
                        <input className="form-check-input" type="checkbox" name="RDVsAtelierSurMobile" checked={this.state.site.RDVsAtelierSurMobile && this.state.site.Est_eSellerLeadActif} onChange={this.onChange.bind(this)} disabled={!this.state.site.Est_eSellerLeadActif}/>
                        &nbsp; RDVs Atelier sur mobile
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col">
                    <div className="form-check">
                      <label className="form-check-label" data-toggle="tooltip" data-trigger="hover" data-placement="top" title={!this.state.site.Est_eSellerLeadActif ? "Le site doit avoir eSeller Lead activé pour pouvoir cocher cette option" : ""}>
                        <input className="form-check-input" type="checkbox" name="PotCommunAppelsSortantsAPV" checked={this.state.site.PotCommunAppelsSortantsAPV && this.state.site.Est_eSellerLeadActif} onChange={this.onChange.bind(this)} disabled={!this.state.site.Est_eSellerLeadActif}/>
                        &nbsp; Pot Commun Appels Sortants APV (Nissan APV)
                      </label>
                      <i className="fa fa-question-circle ml-2" style={{color:"#9A0059"}} data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true" 
                        title="Si cette option est activée, lors de l'acceptation d'une campagne dans Nissan APV,<br/> 
                        la génération des appels sortants sera automatique et ils seront visibles par<br/>
                        tous les utilisateurs APV ayant accès à la page Actions à faire">
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr/>

            {this.renderHautDePageOffre()}

            <hr />

            {this.renderBasDePageOffre()}
            <hr />

            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px', marginBottom: '15px'}}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }

  renderAccesClient(){
    return (
      <div className='container-fluid'>

          <h2>Sites</h2>
          <br />

          <form onSubmit={this.handleSubmit.bind(this)}>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Descr" className="col-sm-3 col-form-label">Nom</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Descr" name="Descr" value={this.state.site.Descr} onChange={this.onChange.bind(this)} maxLength="60" required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Libelle" className="col-sm-3 col-form-label">Libelle (eSeller Lead)</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Libelle" name="Libelle" value={this.state.site.Libelle ? this.state.site.Libelle : ''} onChange={this.onChange.bind(this)} maxLength="100" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Code" className="col-sm-3 col-form-label">Code site</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Code" name="Code" value={this.state.site.Code} onChange={this.onChange.bind(this)} maxLength="25" required='required' />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="CodeConstructeur" className="col-sm-3 col-form-label">Code constructeur</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="CodeConstructeur" name="CodeConstructeur" value={this.state.site.CodeConstructeur ? this.state.site.CodeConstructeur : ''} maxLength="10" disabled readonly/>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Address1" className="col-sm-3 col-form-label">Adresse</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Address1" name="Address1" value={this.state.site.Address1} onChange={this.onChange.bind(this)} maxLength="40" />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Address2" className="col-sm-3 col-form-label">Complément d'adresse</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Address2" name="Address2" value={this.state.site.Address2} onChange={this.onChange.bind(this)} maxLength="40" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="CodePostal" className="col-sm-3 col-form-label">Code postal</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="CodePostal" name="CodePostal" value={this.state.site.CodePostal} onChange={this.onChange.bind(this)} maxLength="10" />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Ville" className="col-sm-3 col-form-label">Ville</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Ville" name="Ville" value={this.state.site.Ville} onChange={this.onChange.bind(this)} maxLength="50" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Tel" className="col-sm-3 col-form-label">Téléphone</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Tel" name="Tel" value={this.state.site.Tel} onChange={this.onChange.bind(this)} maxLength="20" />
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="Fax" className="col-sm-3 col-form-label">Fax</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="Fax" name="Fax" value={this.state.site.Fax ? this.state.site.Fax : ''} onChange={this.onChange.bind(this)} maxLength="20" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="EMail" className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control form-control-sm" id="EMail" name="EMail" value={this.state.site.EMail ? this.state.site.EMail : ''} onChange={this.onChange.bind(this)} maxLength="255" />
                  </div>
                </div>
              </div>

              <div className="col"></div>
            </div>


            <div className="row">
              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDPlaque" className="col-sm-3 col-form-label">Société d'appartenance</label>
                  <div className="col-sm-6">
                  {this.props.match.params.id ?
                    this.state.societes.map((societe, i) => {
                      if((this.state.site.IDPlaque && this.state.site.IDPlaque === societe.IDPlaque))
                          return <input type='text' className="form-control form-control-sm" readonly='readonly' value={societe.Libelle}/>
                    })
                    : 
                    <select value={this.state.site.IDPlaque ? this.state.site.IDPlaque : ''} onChange={this.onChange.bind(this)} id='IDPlaque' name="IDPlaque" className="form-control form-control-sm" required='required'>
                      {!this.state.site.IDPlaque ? <option value=''></option> : null}
                      {this.state.societes.map((societe, i) => {
                        if((this.state.site.IDPlaque && this.state.site.IDPlaque === societe.IDPlaque) || !this.state.site.IDPlaque)
                            return <option key={societe.IDPlaque} value={societe.IDPlaque}>{societe.Libelle}</option>
                      })}
                    </select>
                  }
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="form-group row">
                  <label htmlFor="IDTypeSite" className="col-sm-3 col-form-label">Type de site</label>
                  <div className="col-sm-6">
                    <select value={this.state.site.IDTypeSite ? this.state.site.IDTypeSite : ''} onChange={this.onChange.bind(this)} id='IDTypeSite' name="IDTypeSite" className="form-control form-control-sm" required='required'>
                      <option value=''></option>
                      {this.state.types.map((type, i) => {
                        return <option key={type.IDTypeSite} value={type.IDTypeSite}>{type.Descr}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <hr/>

            {this.renderHautDePageOffre()}

            <hr />

            {this.renderBasDePageOffre()}

            <hr />

            {this.state.updated ?
              <div className="row" style={{ marginTop: '15px', marginBottom: '15px'}}>
                <div className="col text-center">
                  <button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary">
                    {this.state.fetchInProgress ?
                      <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                    : null}
                    Enregistrer
                  </button>
                </div>
              </div>
            : null}
        </form>
      </div>
    );
  }

  renderFormDemande(){
    return (
      <div className='container-fluid'>

          <h2>Sites</h2>
          <br />

          <FormDemande titre="Demande d'ajout d'un site" sujet="Création d'un site" type="ajout_site"/>
      </div>
    );
  }
}

export default Form;
