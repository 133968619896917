import { useEffect, useMemo, useState } from "react";
import BackOfficeAPIClient from "../../clients/BackofficeAPIClient";
import _ from "lodash";
import Select from "react-select"
import { ActivitePublicationConfig, Annonceur, LieuStockage, PublicationGpConfig } from "./PublicationUtilsEnums";



export default function PublicationCompteClient() {
    const backofficeApiClient = new BackOfficeAPIClient()

    const [publicationGpConfig, setPublicationGpConfig] = useState<PublicationGpConfig>()
    const [activitiesConfig, setActivitiesConfig] = useState<ActivitePublicationConfig[]>([])
    const [advertisers, setAdvertisers] = useState<Annonceur[]>([])
    const [fetchingConfig, setFetchingConfig] = useState<boolean>(true)
    const [configLoaded, setConfigLoaded] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [error, setError] = useState<string>("")
    const [updated, setUpdated] = useState<boolean | null>(null)
    
    const canSubmit = useMemo(() => {
        return !submitting && updated && _.every(activitiesConfig.filter(a => a.Actif), (a: ActivitePublicationConfig) => a.LieuStockage)
    }, [submitting, updated, activitiesConfig])

    useEffect(() => {
        fetchConfig()
    }, [])

    useEffect(() => {
        if(configLoaded) {
            setUpdated(false)
        }
    }, [configLoaded])

    useEffect(() => {
        if (configLoaded && updated === false) {
            setUpdated(true);
        }
    }, [publicationGpConfig, activitiesConfig]);

    const fetchConfig = async() => {
        try {
            setFetchingConfig(true)
            await fetchGpConfig()
            await fetchActivitiesConfig()
            await fetchAnnonceurs()
            setConfigLoaded(true)
        }
        catch(err: any) {
            setError(err.message)
        }
        finally {
            setFetchingConfig(false)
        }
    }

    const fetchGpConfig = async () => {
        try {
            setPublicationGpConfig((await backofficeApiClient.get<PublicationGpConfig>("/publication/gpConfig")))
        }
        catch {
            throw new Error("Erreur lors de la récupération de la configuration du groupe")
        }
    }

    const fetchActivitiesConfig = async () => {
        try {
            setActivitiesConfig(_.orderBy(await backofficeApiClient.get<ActivitePublicationConfig[]>("/publication/activities"), "IDActivite"))
        }
        catch {
            throw new Error("Erreur lors de la récupération de la configuration des activités")
        }
    }

    const fetchAnnonceurs = async() => {
        try {
            setAdvertisers(await backofficeApiClient.get<Annonceur[]>("/annonceurs"))
        }
        catch {
            throw new Error("Erreur lors de la récupération des annonceurs")
        }
    }

    const save = async () => {
        try {
            setSubmitting(true)
            await backofficeApiClient.put("/publication/gpConfig", publicationGpConfig)
            await backofficeApiClient.put("/publication/activities", activitiesConfig)
            setUpdated(false)
        }
        catch (erreur) {
            console.log("Erreur lors de l'enregistrement")
        }
        finally {
            setSubmitting(false)
        }
    }

    const setActivityConfig = (IDActivite: number, changes: Partial<ActivitePublicationConfig>) => {
        setActivitiesConfig(prevActivitiesConfig =>
            prevActivitiesConfig.map(activity => activity.IDActivite === IDActivite ? { ...activity, ...changes } : activity)
        )
    }

    return (
        <div className='container-fluid mb-2'>
            <div className="row">
                <div className="col">
                    <h2 className="mb-4">Publication : paramétrage compte client</h2>
                    {fetchingConfig ? 
                        <div className="d-flex flex-col align-items-center justify-content-center">
                            <i className='fa fa-spinner fa-spin fa-fw fa-2x'></i>
                        </div>
                    :error ? 
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <i className='fa fa-warning'></i>
                            {error}
                        </div>
                    :null}
                    
                    {configLoaded && 
                    <>
                        <div className="row mb-5">
                            <div className="col ml-3 py-2">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input className="form-check-input" type="checkbox" name="GRP_PublicationVehicules" checked={publicationGpConfig?.GRP_PublicationVehicules ?? false} onChange={(e) => setPublicationGpConfig({GRP_PublicationVehicules: e.currentTarget.checked})}/>
                                        Fonctionnalité "Publication de véhicules" activée
                                    </label>
                                </div>
                            </div>
                            {updated && canSubmit ?
                                <div className="d-flex flex-row  align-items-center mr-3">
                                    <div className="alert alert-warning m-0 p-1 mr-1">
                                        <i className="fa fa-warning mr-1"></i>
                                        Modifications non sauvegardées
                                    </div>
                                    <button type="submit" className="btn btn-primary m-0 p-1 px-2" onClick={save}>
                                        {submitting && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                                        Valider
                                    </button>
                                </div>
                            : null}
                        </div>

                        <div className="row mb-5">
                            <div className="col ml-3">
                                <h4>Activités</h4>
                                <div style={{display: "flex", "gap": 60}}>
                                {activitiesConfig.map(activityConfig => {
                                    return (
                                        <div className="form-check" key={activityConfig.IDActivite}>
                                            <label className="form-check-label">
                                                <input className="form-check-input" type="checkbox" checked={!!activityConfig.Actif} onChange={(e) => setActivityConfig(activityConfig.IDActivite, {Actif: e.currentTarget.checked})}/>
                                                {activityConfig.LibelleActivite}
                                            </label>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col ml-3">
                                <table className="w-100">
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td style={{width: "min-content", verticalAlign:"top"}}>
                                                <h4>Lieu de stockage</h4>
                                                <p>Sélectionner le système de gestion du lieu de stockage pour chaque activité</p>
                                            </td>
                                            <td style={{verticalAlign:"top"}}>
                                                <h4>Annonceurs</h4>
                                                <p>Sélectionner les annonceurs à utiliser pour chaque activité</p>
                                            </td>
                                            <td style={{verticalAlign:"top"}}>
                                                <h4>Éléments marketing</h4>
                                                <p>Sélectionner les éléments marketing utilisés par le client</p>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {activitiesConfig.filter(a => a.Actif).map((activityConfig, i) => (
                                            <tr key={activityConfig.IDActivite} style={{backgroundColor: i%2 == 0 ? "#DDD": "white"}}>
                                                <th className="pl-2 py-3 pr-1">
                                                    {activityConfig.LibelleActivite}
                                                </th>

                                                <td>
                                                    <div style={{display: "flex", alignItems: "center", gap:3}}>
                                                        <select className="form-control form-control-sm w-auto" value={activityConfig.LieuStockage ?? ""} onChange={(e) => setActivityConfig(activityConfig.IDActivite, {LieuStockage: e.currentTarget.value})}>
                                                            <option value="">Sélectionner le lieu de stockage</option>
                                                            {Object.keys(LieuStockage).map((l) => (
                                                                <option key={l} value={l}>
                                                                    {LieuStockage[l as keyof typeof LieuStockage]}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {!activityConfig.LieuStockage && <i className="fa fa-asterisk text-danger" title="Champ obligatoire"></i>}
                                                    </div>
                                                </td>

                                                <td className="pr-4">
                                                    <Select multi={true}
                                                        value={activityConfig.Annonceurs} 
                                                        options={advertisers.map(a => {return {label: a.Nom, value: a.IDAnnonceur}})} 
                                                        searchable={true} 
                                                        onChange={(selectedAdvertisers: {label: string, value: number}[]) => {
                                                            setActivityConfig(activityConfig.IDActivite, {Annonceurs: selectedAdvertisers.map(a => a.value)})
                                                        }} 
                                                    />
                                                </td>

                                                <td>
                                                    <div style={{display: "flex", gap:40}}>
                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input className="form-check-input" type="checkbox" checked={!!activityConfig.LienVideoActif} onChange={(e) => setActivityConfig(activityConfig.IDActivite, {LienVideoActif: e.currentTarget.checked})}/>
                                                                Lien vidéo
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <label className="form-check-label">
                                                                <input className="form-check-input" type="checkbox" checked={!!activityConfig.Lien360Actif} onChange={(e) => setActivityConfig(activityConfig.IDActivite, {Lien360Actif: e.currentTarget.checked})}/>
                                                                Lien 360°
                                                            </label>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>

            {configLoaded && 
                <div className="row mt-4">
                    <div className="col text-center">
                        <button disabled={!canSubmit} type="submit" className="btn btn-primary" onClick={save}>
                            {submitting && <i className='fa fa-spinner fa-spin fa-fw mr-1'></i>}
                            Valider
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}
